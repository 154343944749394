<template>
  <BCard>
    <BRow>
      <BCol>
        <h4><strong class="text-black text-2xl">Data Pelanggan</strong></h4>
      </BCol>
      <BCol md="2">
        <BButton
          id="download"
          style="padding: 5px 1rem"
          class="mb-1 w-100"
          variant="outline-primary"
          size="sm"
          @click="getDownloadContact"
        >
          <div class="flex items-center justify-center">
            <span class="k-document-download text-primary text-2xl" />
            <span class="ml-[4px]">Download Excel</span>
          </div>
        </BButton>
      </BCol>
    </BRow>
    <BRow class="justify-content-end">
      <BCol md="3">
        <BInputGroup class="input-group-merge mb-1">
          <BInputGroupPrepend is-text>
            <feather-icon icon="SearchIcon" />
          </BInputGroupPrepend>
          <BFormInput
            v-model="search"
            size="md"
            placeholder="Nama pelanggan"
            style="padding: 8px 1rem"
            @input="searchData"
          />
        </BInputGroup>
      </BCol>
      <BCol md="3">
        <BDropdown
          variant="outline-danger"
          :text="handleTextDropdown(provinceName)"
          menu-class="h-80 overflow-auto"
          class="dropdown w-100 mb-1"
          size="md"
        >
          <BDropdownItem @click="filterDataByProvince()">
            Semua Provinsi
          </BDropdownItem>
          <BDropdownItem
            v-for="(items, index) in provinces"
            :key="index"
            v-model="provinceName"
            @click="filterDataByProvince(items.province_name)"
          >
            {{ items.province_name }}
          </BDropdownItem>
        </BDropdown>
      </BCol>
    </BRow>
    <BOverlay
      :show="isLoading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity="0.5"
      rounded="sm"
    >
      <BTable
        :items="items"
        :fields="fields"
        show-empty
        empty-text="Tidak ada data yang ditampilkan."
        responsive
        class="mt-1 table table-responsive"
        selectable
        select-mode="single"
        @row-selected="handleToDetail"
      >
        <template #head(customer_phone)="data">
          <b-row class="align-items-center">
            <span>{{ data.label }}</span>
            <b-img
              id="infoNo"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              class="ml-50"
            />
            <b-popover
              triggers="hover"
              target="infoNo"
              placement="topright"
            >Akan ada icon penanda jika nomor HP pelanggan terdaftar di WhatsApp</b-popover>
          </b-row>
        </template>

        <template #cell(customer_name)="data">
          <div style="min-width: 20px !important">
            {{ data.item.customer_name }}
          </div>
        </template>

        <template #cell(customer_phone)="data">
          <div
            class="d-flex align-items-center"
            style="min-width: 20px !important"
            @mouseover="handleHoverButtonWa(data)"
            @mouseleave="handleLeaveHoverButtonWa(data)"
            @click="handlePhone(data)"
          >
            <b-img
              v-if="data.item.is_whatsapp === 1"
              :src="data.item.wa_icon"
              alt="Komerce"
              style="cursor: pointer"
              class="mr-50"
            />
            <span :class="`text-[${data.item.text_color}]`">{{ data.item.customer_phone }}</span>
          </div>
        </template>

        <template #cell(customer_address)="data">
          <div style="min-width: 20px !important">
            {{ data.item.customer_address }}
          </div>
        </template>

        <template #cell(total_spent)="data">
          <div style="min-width: 150px !important">
            {{ IDR(data.item.total_spent, 2, 2) }}
          </div>
        </template>

        <template #cell(last_order)="data">
          <div style="min-width: 20px !important">
            {{ DAY_MONTH_YEAR(data.item.last_order) }}
          </div>
        </template>

        <template #cell(total_pcs)="data">
          <div style="min-width: 20px !important">
            {{ data.item.total_pcs }}
          </div>
        </template>

        <template #cell(total_order)="data">
          <div style="min-width: 20px !important">
            {{ data.item.total_order }}
          </div>
        </template>

      </BTable>
      <div class="flex justify-between items-center">
        <div
          class="
            bg-light
            d-flex
            justify-content-center
            align-items-center
            p-50
            rounded
          "
        >
          <span class="text-black mr-1"> List per halaman: </span>
          <BButton
            v-for="page in optionsPage"
            :key="page"
            class="btn-icon"
            size="sm"
            :variant="totalPerPage === page ? 'primary' : 'flat-dark'"
            @click="setPerPage(page)"
          >
            {{ page }}
          </BButton>
        </div>

        <BPagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="totalPerPage"
          first-number
          last-number
          class="pagination-primary mb-0"
        >
          <template #prev-text>
            <feather-icon
              size="18"
              icon="ChevronLeftIcon"
            />
          </template>
          <template #next-text>
            <feather-icon
              size="18"
              icon="ChevronRightIcon"
            />
          </template>
        </BPagination>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/formatDate'
import { fieldsCardList } from './config'

export default {
  components: {},
  data() {
    return {
      isLoading: true,
      provinces: [],
      provinceName: '',
      items: [],
      fields: fieldsCardList,
      currentPage: 1,
      totalRows: 0,
      optionsPage: [50, 100, 200],
      totalPerPage: 50,
      IDR,
      DAY_MONTH_YEAR,
    }
  },
  watch: {
    currentPage: {
      handler() {
        this.getCustomer()
      },
    },
    provinceName: {
      handler() {
        this.getCustomer()
      },
    },
  },
  created() {
    this.getCustomer()
  },
  mounted() {
    this.getProvince()
  },
  methods: {
    async getCustomer() {
      const params = {}
      Object.assign(params, { search: this.search })
      Object.assign(params, { province_name: this.provinceName })
      Object.assign(params, { total_per_page: this.totalPerPage })
      Object.assign(params, { page: this.currentPage })
      await this.$http_komship.get('/v2/customers', { params })
        .then(async res => {
          const { data } = res.data.data
          this.items = data
          // eslint-disable-next-line array-callback-return
          await this.items.map((item, index) => {
            // eslint-disable-next-line global-require
            Object.assign(this.items[index], { wa_icon: require('@/assets/images/icons/icon-wa-notactive.svg') })
            Object.assign(this.items[index], { text_color: 'black' })
          })
          this.totalRows = res.data.data.total
          this.isLoading = false
          this.$forceUpdate()
        })
        .catch(err => {
          this.$toast_error({ message: err.message })
          this.isLoading = false
        })
    },
    async getDownloadContact() {
      await this.$http_komship.get('/v1/customer/export')
        .then(res => {
          const { data } = res
          const a = document.createElement('a')
          a.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`
          a.download = 'Data Kontak.xls'
          a.click()
        })
        .catch(err => {
          this.$toast_error({ message: err.message })
          this.isLoading = false
        })
    },
    async getProvince() {
      await this.$http_komship.get('/v1/provinces')
        .then(res => {
          const { data } = res.data
          this.provinces = data
        })
        .catch(err => {
          this.$toast_error({ message: err.message })
          this.isLoading = false
        })
    },
    searchData: _.debounce(function search() {
      this.getCustomer()
    }, 1000),
    setPerPage(page) {
      this.totalPerPage = page
      this.getCustomer()
    },
    filterDataByProvince(value) {
      this.provinceName = value
    },
    handleTextDropdown(value) {
      if (value) {
        return value
      }
      return 'Semua Provinsi'
    },
    handleToDetail(value) {
      const idCustomer = value[0].customer_id
      this.$router.push({
        path: `/info-customer/detail-customer/${idCustomer}`,
      })
    },
    handlePhone(data) {
      if (data.item.is_whatsapp === 1) {
        window.open(`https://wa.me/62${data.item.customer_phone.substring(1)}`, '_blank')
      }
    },
    handleHoverButtonWa(data) {
      if (data.item.is_whatsapp === 1) {
        // eslint-disable-next-line global-require, no-param-reassign
        data.item.wa_icon = require('@/assets/images/icons/whatsapp.svg')
        this.$forceUpdate()
      }
      // eslint-disable-next-line no-param-reassign
      data.item.text_color = '#34A770'
      this.$forceUpdate()
    },
    handleLeaveHoverButtonWa(data) {
      if (data.item.is_whatsapp === 1) {
        // eslint-disable-next-line global-require, no-param-reassign
        data.item.wa_icon = require('@/assets/images/icons/icon-wa-notactive.svg')
        this.$forceUpdate()
      }
      // eslint-disable-next-line no-param-reassign
      data.item.text_color = 'black'
      this.$forceUpdate()
    },
  },
}
</script>
