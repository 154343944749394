<template>
  <BRow
    v-if="show && isKomchat !== 1"
    class="custom-bg mx-1 rounded-lg"
  >
    <BCol
      md="8"
      class="align-self-center pl-3 py-2"
    >
      <h3 class="text-black">
        Broadcast Data Pelanggan Komship dengan Komchat Lite
      </h3>
      <h6 class="my-2">
        Gunakan data kontak Komship kamu untuk blast pesan WhatsApp langsung ke
        pelanggan
      </h6>
      <a
        href="https://komchat.id/lite/"
        target="_blank"
      >
        <BButton
          variant="primary"
          class="rounded-lg"
        >
          Pakai Komchat Sekarang
        </BButton>
      </a>
    </BCol>
    <BCol
      id="komshipxkomchat"
      class="d-flex justify-content-end"
    >
      <img
        class="w-80"
        src="https://storage.googleapis.com/komerce/assets/LP-Komchat/lp-komchat-ilustration-Mudah Kelola Target Customer.webp"
        alt="komchatxkomship"
      >
      <span
        class="k-close text-black text-3xl cursor-pointer"
        @click="show = false"
      />
    </BCol>
  </BRow>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      show: true,
      isKomchat: 0,
    }
  },
  computed: {
    ...mapState('dashboard', ['profile']),
  },
  mounted() {
    this.isKomchat = this.profile.is_komchat
  },
}
</script>

<style scoped>
.custom-bg {
  background-color: #FFE3E3;
  background: linear-gradient(rgba(255, 243, 243, 1), rgba(255, 227, 227, 1));
}
#komshipxkomchat {
  background-image: url(https://storage.googleapis.com/komerce/assets/illustration/Logo-product-komchat-texture.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-position-y: 30%;
  background-position-x: 100%;
}
</style>
