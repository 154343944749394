<template>
  <div>
    <CardChart />
    <CardList />
  </div>
</template>
<script>
import CardChart from './InfoCustomer/CardChart.vue'
import CardList from './InfoCustomer/CardList.vue'

export default {
  components: {
    CardChart,
    CardList,
  },
}
</script>
