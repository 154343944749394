export const fieldsCardList = [
  {
    key: 'customer_name',
    label: 'Nama Pelanggan',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
    sortable: true,
  },
  {
    key: 'customer_phone',
    label: 'No. HP',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'customer_address',
    label: 'Alamat',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
  },
  {
    key: 'total_order',
    label: 'Total Order',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
    sortable: true,
  },
  {
    key: 'total_pcs',
    label: 'Total Pcs',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
    sortable: true,
  },
  {
    key: 'total_spent',
    label: 'Total Belanja',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
    sortable: true,
  },
  {
    key: 'last_order',
    label: 'Terakhir Order',
    class: 'p-1',
    thStyle: {
      fontSize: '14px',
      color: '#6E6B7B',
      textTransform: 'capitalize',
    },
    sortable: true,
  },
]

export const chartOptionsCard = {
  chart: {
    type: 'area',
    stacked: false,
    height: 350,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.75,
      opacityTo: 0.05,
      stops: [20, 100, 100, 100],
    },
  },
  yaxis: {
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    type: 'category',
    categories: [],
    min: '',
  },
  noData: {
    text: 'Tidak ada data',
  },
  color: ['#08A0F7'],
  tooltip: {
    shared: false,
    custom({
      series, seriesIndex, dataPointIndex, w,
    }) {
      const seriesName = w.globals.seriesNames[seriesIndex]
      const seriesValue = series[seriesIndex][dataPointIndex]
      const seriesMonth = w.globals.categoryLabels[dataPointIndex].slice(0, 3)
      const seriesYear = w.config.xaxis.min[dataPointIndex]

      return `<div class="px-1 py-75" style="box-shadow: 0px 8px 8px rgba(50, 50, 71, 0.08), 0px 8px 16px rgba(50, 50, 71, 0.06);">
            <div class="row">
              <div class="col flex">
                <div class="mb-25 mr-1"><small class="text-muted">${seriesName}</small></div>
                <div class="font-weight-bolder text-dark mb-0 h4 text-sm">${seriesValue}</div>
              </div>
            </div>
            <div class="text-muted">${seriesMonth} - ${seriesYear}</div>
          </div>`
    },
  },
}
