<template>
  <div>
    <BCard>
      <h4 class="mb-2">
        <strong class="text-black text-2xl">Data Kontak</strong>
      </h4>
      <CardKomchat class="mb-4" />
      <BRow>
        <BCol
          md="6"
          class="d-flex"
        >
          <div>
            <span class="k-chart-3 bg-[#F8F8F8] p-[6px] mr-[10px] rounded" />
          </div>
          <div>
            <h5>
              <strong
                style="color: #000000"
                class="text-xl"
              >
                Pertumbuhan Pelanggan
              </strong>
            </h5>
            <h6>
              Grafik Peningkatan petumbahan jumlah pelanggan
            </h6>
          </div>
        </BCol>
        <BCol
          md="6"
          class="text-end"
        >
          <BButton
            size="sm"
            variant="outline-primary"
            style="padding: 0.4rem 2rem; border: 1px solid #ECE9F1 !important; color: black;"
            class="cursor-pointer"
          >
            <BRow>
              <Datepicker
                v-model="filterChart"
                :format="formatDateFilter"
                minimum-view="year"
                name="datepicker"
                wrapper-class="border-solid border-slate-200 rounded w-auto"
                calendar-class="w-300 ml-[-10em]"
              />
              <b-img
                src="@/assets/images/icons/arrow-down-light.svg"
                class="w-3"
              />
            </BRow>
          </BButton>
        </BCol>
      </BRow>
      <div class="mt-1">
        <BOverlay
          :show="isLoading"
          spinner-variant="primary"
          variant="light"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <VueApexcharts
            ref="myChart"
            height="300"
            type="area"
            :options="chartOptions"
            :series="seriesChart"
          />
        </BOverlay>
      </div>
    </BCard>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import VueApexcharts from 'vue-apexcharts'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
import CardKomchat from './CardKomchat.vue'
import { chartOptionsCard } from './config'

export default {
  components: {
    Datepicker,
    VueApexcharts,
    CardKomchat,
  },
  data() {
    return {
      isLoading: true,
      seriesChart: [],
      filterChart: moment().format('YYYY-MM-DD'),
      chartOptions: chartOptionsCard,
    }
  },
  watch: {
    filterChart: {
      handler() {
        this.getCustomerGrowth()
      },
    },
  },
  mounted() {
    this.getCustomerGrowth()
  },
  methods: {
    async getCustomerGrowth() {
      this.isLoading = true
      const params = {}
      Object.assign(params, {
        filter: this.formatDateFilter(this.filterChart),
      })
      await this.$http_komship('/v1/customer/contact-growth', { params })
        .then(res => {
          const { data } = res.data
          this.seriesChart = [
            {
              name: 'Pelanggan',
              data: data.map(
                item => isEmpty(item.total.total_contact) && item.total.total_contact,
              ),
            },
          ]
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(item => item.total.month),
              min: data.map(item => item.total.year),
            },
          }
          this.isLoading = false
        })
        .catch(err => {
          this.$toast_error({ message: err.message })
          this.isLoading = false
        })
    },
    formatDateFilter(value) {
      return moment(value)
        .startOf('year')
        .format('YYYY')
        .valueOf()
    },
  },
}
</script>
